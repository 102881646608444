const digitsRE = /(\d{3})(?=\d)/g

export const currency = (value, currency, decimals) => {
  value = parseFloat(value)
  if (!isFinite(value) || (!value && value !== 0)) return ''
  currency = currency != null ? currency : '¥'
  decimals = decimals != null ? decimals : 0
  var stringified = Math.abs(value).toFixed(decimals)
  var _int = decimals
    ? stringified.slice(0, -1 - decimals)
    : stringified
  var i = _int.length % 3
  var head = i > 0
    ? (_int.slice(0, i) + (_int.length > 3 ? ',' : ''))
    : ''
  var _float = decimals
    ? stringified.slice(-1 - decimals)
    : ''
  var sign = value < 0 ? '-' : ''
  return sign + currency + head +
    _int.slice(i).replace(digitsRE, '$1,') +
    _float
}
export const imageUrl = (val) => {
  if (!val) return `${process.env.VUE_APP_API_ROOT}../storage/images/users/add_photo2.png`;
  if (val.slice(0,4) === 'http') return val;
  return `${process.env.VUE_APP_API_ROOT}../${val}`;
}

export const thumbnail = (val, width=75) => {
  if (!val.match(/\.(jpeg|png)(\?|$)/)) {
    return val;
  }
  const match = val.match(/^(https?:\/\/[^/]+)/i);
  if (match) {
    val = val.replace(match[1], '');
    return `${match[1]}/tw/${width}${val}`;
  }
  return `/tw/${width}/${val}`;
}
export const truncate = (str, len=50) => {
  return str.length <= len ? str: (str.substr(0, len)+"...");
}
/*****************************
 *  電話系
 */
export const encodeInternationalPhoneNumber = (value) => {
  if (!value) return '';
  const internationalCode = '+81';  // 日本
  if (value.match(/^\+\d+$/)) {
    return value;
  } else {
    value = value.replace(/\D/g, '');
    if (value[0] === '0') {
      value = internationalCode + value.substr(1);
    }
    return value;
  }
};
export const decodeInternationalPhoneNumber = (value) => {
  let phone;
  if (!value) return '';
  if (value.match(/[^0-9\-+]/)) return value;
  // 日本国内の国際番号であれば国際コードは外す
  if (value.match(/^\+81/)) {
    phone = '0' + value.substr(3);
  } else if (value.match(/^\+1[0-9]{10}/)) {
    // SMS用のアメリカの番号
    return value.substr(2, 3) + '-' + value.substr(5, 3) + '-' + value.substr(8, 4);
  } else {
    phone = value;
  }

  phone = phone.replace(/[０-９]/g, (s) => {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  }).replace(/ー/g, '-').replace(/[^0-9+-]/g, '');

  let phoneSeparates = {
    '1267': 'E',
    '1372': 'E',
    '1374': 'E',
    '1377': 'E',
    '1392': 'E',
    '1397': 'E',
    '1398': 'E',
    '1456': 'E',
    '1457': 'E',
    '1466': 'E',
    '1547': 'E',
    '1558': 'E',
    '1564': 'E',
    '1586': 'E',
    '1587': 'E',
    '1632': 'E',
    '1634': 'E',
    '1635': 'E',
    '1648': 'E',
    '1654': 'E',
    '1655': 'E',
    '1656': 'E',
    '1658': 'E',
    '4992': 'E',
    '4994': 'E',
    '4996': 'E',
    '4998': 'E',
    '5769': 'E',
    '5979': 'E',
    '7468': 'E',
    '8387': 'E',
    '8388': 'E',
    '8396': 'E',
    '8477': 'E',
    '8512': 'E',
    '8514': 'E',
    '9496': 'E',
    '9802': 'E',
    '9912': 'E',
    '9913': 'E',
    '9969': 'E',
    '120': 'DEF', // 着信課金用電話番号
    '123': 'DE',
    '124': 'DE',
    '125': 'DE',
    '126': 'DE',
    '133': 'DE',
    '134': 'DE',
    '135': 'DE',
    '136': 'DE',
    '137': 'DE',
    '138': 'DE',
    '139': 'DE',
    '142': 'DE',
    '143': 'DE',
    '144': 'DE',
    '145': 'DE',
    '146': 'DE',
    '152': 'DE',
    '153': 'DE',
    '154': 'DE',
    '155': 'DE',
    '156': 'DE',
    '157': 'DE',
    '158': 'DE',
    '162': 'DE',
    '163': 'DE',
    '164': 'DE',
    '165': 'DE',
    '166': 'DE',
    '167': 'DE',
    '172': 'DE',
    '173': 'DE',
    '174': 'DE',
    '175': 'DE',
    '176': 'DE',
    '178': 'DE',
    '179': 'DE',
    '182': 'DE',
    '183': 'DE',
    '184': 'DE',
    '185': 'DE',
    '186': 'DE',
    '187': 'DE',
    '191': 'DE',
    '192': 'DE',
    '193': 'DE',
    '194': 'DE',
    '195': 'DE',
    '197': 'DE',
    '198': 'DE',
    '220': 'DE',
    '223': 'DE',
    '224': 'DE',
    '225': 'DE',
    '226': 'DE',
    '228': 'DE',
    '229': 'DE',
    '233': 'DE',
    '234': 'DE',
    '235': 'DE',
    '237': 'DE',
    '238': 'DE',
    '240': 'DE',
    '241': 'DE',
    '242': 'DE',
    '243': 'DE',
    '244': 'DE',
    '246': 'DE',
    '247': 'DE',
    '248': 'DE',
    '250': 'DE',
    '254': 'DE',
    '255': 'DE',
    '256': 'DE',
    '257': 'DE',
    '258': 'DE',
    '259': 'DE',
    '260': 'DE',
    '261': 'DE',
    '263': 'DE',
    '264': 'DE',
    '265': 'DE',
    '266': 'DE',
    '267': 'DE',
    '268': 'DE',
    '269': 'DE',
    '270': 'DE',
    '274': 'DE',
    '276': 'DE',
    '277': 'DE',
    '278': 'DE',
    '279': 'DE',
    '280': 'DE',
    '282': 'DE',
    '283': 'DE',
    '284': 'DE',
    '285': 'DE',
    '287': 'DE',
    '288': 'DE',
    '289': 'DE',
    '291': 'DE',
    '293': 'DE',
    '294': 'DE',
    '295': 'DE',
    '296': 'DE',
    '297': 'DE',
    '299': 'DE',
    '422': 'DE',
    '428': 'DE',
    '436': 'DE',
    '438': 'DE',
    '439': 'DE',
    '460': 'DE',
    '463': 'DE',
    '465': 'DE',
    '466': 'DE',
    '467': 'DE',
    '470': 'DE',
    '475': 'DE',
    '476': 'DE',
    '478': 'DE',
    '479': 'DE',
    '480': 'DE',
    '493': 'DE',
    '494': 'DE',
    '495': 'DE',
    '531': 'DE',
    '532': 'DE',
    '533': 'DE',
    '536': 'DE',
    '537': 'DE',
    '538': 'DE',
    '539': 'DE',
    '544': 'DE',
    '545': 'DE',
    '547': 'DE',
    '548': 'DE',
    '550': 'DE',
    '551': 'DE',
    '553': 'DE',
    '554': 'DE',
    '555': 'DE',
    '556': 'DE',
    '557': 'DE',
    '558': 'DE',
    '561': 'DE',
    '562': 'DE',
    '563': 'DE',
    '564': 'DE',
    '565': 'DE',
    '566': 'DE',
    '567': 'DE',
    '568': 'DE',
    '569': 'DE',
    '570': 'DEF',  // 統一番号用電話番号
    '572': 'DE',
    '573': 'DE',
    '574': 'DE',
    '575': 'DE',
    '576': 'DE',
    '577': 'DE',
    '578': 'DE',
    '581': 'DE',
    '584': 'DE',
    '585': 'DE',
    '586': 'DE',
    '587': 'DE',
    '594': 'DE',
    '595': 'DE',
    '596': 'DE',
    '597': 'DE',
    '598': 'DE',
    '599': 'DE',
    '600': 'DEF', // ＦＭＣ用電話番号
    '721': 'DE',
    '725': 'DE',
    '735': 'DE',
    '736': 'DE',
    '737': 'DE',
    '738': 'DE',
    '739': 'DE',
    '740': 'DE',
    '742': 'DE',
    '743': 'DE',
    '744': 'DE',
    '745': 'DE',
    '746': 'DE',
    '747': 'DE',
    '748': 'DE',
    '749': 'DE',
    '761': 'DE',
    '763': 'DE',
    '765': 'DE',
    '766': 'DE',
    '767': 'DE',
    '768': 'DE',
    '770': 'DE',
    '771': 'DE',
    '772': 'DE',
    '773': 'DE',
    '774': 'DE',
    '776': 'DE',
    '778': 'DE',
    '779': 'DE',
    '790': 'DE',
    '791': 'DE',
    '794': 'DE',
    '795': 'DE',
    '796': 'DE',
    '797': 'DE',
    '798': 'DE',
    '799': 'DE',
    '800': 'DEF',  // 着信課金用電話番号
    '820': 'DE',
    '823': 'DE',
    '824': 'DE',
    '826': 'DE',
    '827': 'DE',
    '829': 'DE',
    '833': 'DE',
    '834': 'DE',
    '835': 'DE',
    '836': 'DE',
    '837': 'DE',
    '838': 'DE',
    '845': 'DE',
    '846': 'DE',
    '847': 'DE',
    '848': 'DE',
    '852': 'DE',
    '853': 'DE',
    '854': 'DE',
    '855': 'DE',
    '856': 'DE',
    '857': 'DE',
    '858': 'DE',
    '859': 'DE',
    '863': 'DE',
    '865': 'DE',
    '866': 'DE',
    '867': 'DE',
    '868': 'DE',
    '869': 'DE',
    '875': 'DE',
    '877': 'DE',
    '879': 'DE',
    '880': 'DE',
    '883': 'DE',
    '884': 'DE',
    '885': 'DE',
    '887': 'DE',
    '889': 'DE',
    '892': 'DE',
    '893': 'DE',
    '894': 'DE',
    '895': 'DE',
    '896': 'DE',
    '897': 'DE',
    '898': 'DE',
    '920': 'DE',
    '930': 'DE',
    '940': 'DE',
    '942': 'DE',
    '943': 'DE',
    '944': 'DE',
    '946': 'DE',
    '947': 'DE',
    '948': 'DE',
    '949': 'DE',
    '950': 'DE',
    '952': 'DE',
    '954': 'DE',
    '955': 'DE',
    '956': 'DE',
    '957': 'DE',
    '959': 'DE',
    '964': 'DE',
    '965': 'DE',
    '966': 'DE',
    '967': 'DE',
    '968': 'DE',
    '969': 'DE',
    '972': 'DE',
    '973': 'DE',
    '974': 'DE',
    '977': 'DE',
    '978': 'DE',
    '979': 'DE',
    '980': 'DE',
    '982': 'DE',
    '983': 'DE',
    '984': 'DE',
    '985': 'DE',
    '986': 'DE',
    '987': 'DE',
    '990': 'DEF',  // 情報料代理徴収用電話番号
    '993': 'DE',
    '994': 'DE',
    '995': 'DE',
    '996': 'DE',
    '997': 'DE',
    '11': 'CDE',
    // '15': 'CDE',
    // '17': 'CDE',
    // '18': 'CDE',
    // '19': 'CDE',
    '20': 'CDE',  // M2M等専用番号
    // '22': 'CDE',
    // '23': 'CDE',
    // '24': 'CDE',
    // '25': 'CDE',
    // '26': 'CDE',
    // '27': 'CDE',
    // '28': 'CDE',
    // '29': 'CDE',
    // '42': 'CDE',
    // '43': 'CDE',
    // '44': 'CDE',
    // '45': 'CDE',
    // '46': 'CDE',
    // '47': 'CDE',
    // '48': 'CDE',
    // '49': 'CDE',
    '50': 'BCDE', // IP電話の電話番号
    // '52': 'CDE',
    // '53': 'CDE',
    // '54': 'CDE',
    // '55': 'CDE',
    // '58': 'CDE',
    // '59': 'CDE',
    '70': 'BCDE', // 携帯電話・PHSの電話番号
    // '72': 'CDE',
    // '73': 'CDE',
    // '75': 'CDE',
    // '76': 'CDE',
    // '77': 'CDE',
    // '78': 'CDE',
    // '79': 'CDE',
    '80': 'BCDE', // 携帯電話・PHSの電話番号
    // '82': 'CDE',
    // '83': 'CDE',
    // '84': 'CDE',
    // '86': 'CDE',
    // '87': 'CDE',
    // '88': 'CDE',
    // '89': 'CDE',
    '90': 'BCDE', // 携帯電話・PHSの電話番号
    // '92': 'CDE',
    // '93': 'CDE',
    // '95': 'CDE',
    // '96': 'CDE',
    // '97': 'CDE',
    // '98': 'CDE',
    // '99': 'CDE',
    '3': 'BCDE',
    '4': 'BCDE',
    '6': 'BCDE'
  };
  Object.keys(phoneSeparates).reverse().some(key => {
    let reg = new RegExp('^0' + key);
    let found = reg.test(phone);
    if (found) {
      // console.log(key, phoneSeparates[key]);
      let insertNum = key.length + 1;
      if (phone[insertNum] !== '-') {
        phone = phone.slice(0, insertNum) + '-' + phone.slice(insertNum);
      }
      insertNum = key.length + phoneSeparates[key].length + 2;
      if (phone.length >= insertNum && phone[insertNum] !== '-') {
        phone = phone.slice(0, insertNum) + '-' + phone.slice(insertNum);
      }
      return true;
    }
  });
  return phone;
};
export const encodeJapanPhoneNumber = (value) => {
  let phone;
  // 日本国内の国際番号であれば国際コードは外す
  if (!value.match(/^\+81/)) {
    phone = value;
  } else {
    phone = '+81' + value.substr(3);
  }
  return phone;
};
