import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VModal from 'vue-js-modal';
import 'vue-js-modal/dist/styles.css';
import http from '@/services/http';
import Toasted from 'vue-toasted';
import VueCookie from 'vue-cookie';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { currency, imageUrl, thumbnail, encodeInternationalPhoneNumber, decodeInternationalPhoneNumber, truncate } from '@/util'

Vue.config.productionTip = false;

Vue.use(VModal);
Vue.use(http, {store, router});
Vue.use(Toasted);
Vue.use(VueCookie);
Vue.use(VueSweetalert2);
Vue.filter('currency', currency);
Vue.filter('imageUrl', imageUrl);
Vue.filter('thumbnail', thumbnail);
Vue.filter('encodeInternationalPhoneNumber', encodeInternationalPhoneNumber);
Vue.filter('decodeInternationalPhoneNumber', decodeInternationalPhoneNumber);
Vue.filter('truncate', truncate);

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
