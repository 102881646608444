// import Vue from 'vue';

export default {
    setLoginUser(state, user) {
        state.loginUser = user;
    },
    setTalkRoomId(state, roomId) {
        state.talkRoomId = roomId;
    },
    setLoading(state) {
        state.loading = true;
    },
    resetLoading(state) {
        state.loading = false;
    },
    recordTutorial(state) {
        state.readTutorial = true;
    },

    // talkRoom
    setTalkRoomSelectedUser(state, user) {
        state.talkRoom.selectedUser = user;
    },
    setTalkRoomPreviewImage(state, image) {
        state.talkRoom.preview_image = image;
    },
    setTalkRoomSelectedRemark(state, remark) {
        state.talkRoom.selectedRemark = remark;
    },
    setLastReadTalkRoomRemarks(state, payload) {
        console.log('setLastReadTalkRoomRemarks');
        if (payload.talk_room_id) {
            state.talkRoom.lastReadTalkRoomRemarks[payload.talk_room_id] = payload.chat_id;
        }
    },
    setLastReadTalkRoom(state, talk_room_id) {
        state.talkRoom.lastReadTalkRoom = talk_room_id;
    },
    setCart(state, items) {
        state.cart = items;
    },
    addCart(state, item) {
        state.cart.push(item);
    },
    removeCart(state, item) {
        state.cart.splice(state.cart.indexOf(item), 1);
    },
    setStoredDigitalContents(state, val) {
        state.storedDigitalContents = val;
    }
};
