import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';

Vue.use(VueRouter);

const routes = [
    {path: '', redirect: '/home'},
    {path: '/app', redirect: '/home'},  // localでのアプリを開いたページ
    {path: '/tutorial', name: 'tutorial', component: () => import('@/views/AppTutorial')},
    {path: '/login', name: 'login', component: () => import('@/views/AppLogin')},
    {path: '/signup', name: 'signup', component: () => import('@/views/SignupForm')},
    {
        path: '/signup/:user_id/:remember_token/authorization',
        name: 'authorization',
        component: () => import('@/views/SignupAuthorization'),
    },
    {path: '/signupArtist', name: 'signup-artist', component: () => import('@/views/SignupArtistForm')},
    {
        path: '/artist-signup/:artist_id/:remember_token/authorization',
        name: 'authorization-signup',
        component: () => import('@/views/SignupArtistAuthorization'),
    },
    // {path: '/signup-confirm', name: 'signup-confirm', component: () => import('@/views/SignupConfirm')},
    {path: '/logout', name: 'logout', component: () => import('@/views/AppLogout'), meta: {requiresAuth: true}},
    {path: '/artist', name: 'artist', component: () => import('@/views/ArtistList'), meta: { headerBack: '/my-page'}},
    {path: '/artist/:artist', name: 'artist-detail', component: () => import('@/views/ArtistDetail'), meta: {requiresAuth: false, headerBack: '/artist-add'}},
    {path: '/talk_room/:talk_room/subscription/:payment_plan', name: 'subscription',
        component: () => import('@/views/Subscription'), meta: {bodyId: 'entry'}},
    {
        path: '/talk_room/:talk_room/subscription/:payment_plan/end',
        name: 'subscription-end',
        component: () => import('@/views/SubscriptionEnd'),
        meta: {bodyId: 'entry'}
    },
    {path: '/password', name: 'password', component: () => import('@/views/PasswordForm'), meta: {bodyId: 'white'}},
    {path: '/password/send', name: 'password-send', component: () => import('@/views/PasswordSend'), meta: {bodyId: 'layout_yellow'}},
    {path: '/password/input/:user_id/:remember_token', name: 'password-input', component: () => import('@/views/PasswordInput'), meta: {bodyId: 'layout_yellow'}},
    {path: '/my-page', name: 'mypage', component: () => import('@/views/MyPage'), meta: {requiresAuth: true}},
    {path: '/artist-participants-list', name: 'artist-participants-list', component: () => import('@/views/ArtistParticipantsList'), meta: {requiresAuth: true}},
    {path: '/artist-add', name: 'artist-add', component: () => import('@/views/ArtistAdd'), meta: {requiresAuth: true, headerBack: '/my-page'}},
    {path: '/home', name: 'home', component: () => import('@/views/AppHome'), meta: {requiresAuth: true}},
    {path: '/talk/:talk_room', name: 'talk', component: () => import('@/views/AppChat'), meta: {requiresAuth: true, bodyId: 'talk', headerBack: '/my-page'}},
    {path: '/talk/:talk_room/:chat_id', name: 'talk-comment', component: () => import('@/views/AppChat'), meta: {requiresAuth: true, bodyId: 'talk', headerBack: '/my-page'}},

    {
        path: '/news', component: () => import('@/views/AppNewsParent'), children: [
            {path: '', name: 'news', component: () => import('@/views/AppNewsList')},
            {path: ':news', name: 'news-detail', component: () => import('@/views/AppNewsDetail')},
        ],
    },
    {path: '/faq', name: 'faq', component: () => import('@/views/PageFAQ')},
    {path: '/master-faq', name: 'master-faq', component: () => import('@/views/PageMasterFAQ'), meta: {bodyId: 'faq'}},
    {path: '/rule', name: 'rule', component: () => import('@/views/PageRule'), meta: {bodyId: 'rule'}},
    {path: '/privacy', name: 'privacy', component: () => import('@/views/PagePrivacy'), meta: {bodyId: 'privacy'}},
    {path: '/law', name: 'law', component: () => import('@/views/PageLaw'), meta: {bodyId: 'law'}},
    {path: '/maintenance', name: 'maintenance', component: () => import('@/views/AppMaintenance')},
    {
        path: '/contact', component: () => import('@/views/ContactParent'), children: [
            {path: '', name: 'contact-form', component: () => import('@/views/ContactForm')},
            {path: 'confirm', name: 'contact-confirm', component: () => import('@/views/ContactConfirm')},
            {path: 'send', name: 'contact-send', component: () => import('@/views/ContactSend')},
        ],
    },
    {path: '/user_edit', name: 'user-edit', component: () => import('@/views/UserEdit'),
        meta: {requiresAuth: true, bodyId: 'signup'}},
    {
        path: '/items/create', component: () => import('@/views/ArtistItemCreateParent'), props: true, children: [
            {path: '', name: 'item-create', component: () => import('@/views/ArtistItemCreateForm')},
            {path: 'confirm', name: 'item-create-confirm', component: () => import('@/views/ArtistItemCreateConfirm')},
            {path: 'send', name: 'item-create-send', component: () => import('@/views/ArtistItemCreateSend')},
        ],
    },
    {path: '/items', name: 'items', component: () => import('@/views/ArtistItem'), meta: {bodyId: 'layout_yellow'}},
    {path: '/orders', name: 'orders', component: () => import('@/views/ArtistOrders')},
    {path: '/orders/:id', name: 'order-detail', component: () => import('@/views/ArtistOrderDetail')},
    {path: '/orders/:id/edit', name: 'order-edit', component: () => import('@/views/ArtistOrderEdit')},
    {
        path: '/cart', name: 'cart', component: () => import('@/views/CartParent'), children: [
            {path: '', name: 'cart-create', component: () => import('@/views/CartForm')},
            {path: 'confirm', name: 'cart-confirm', component: () => import('@/views/CartConfirm')},
            {path: 'send', name: 'cart-send', component: () => import('@/views/CartSend')},
        ],
    },

    {path: '/ai_chat_room', name: 'ai-chat-room', component: () => import('@/views/AiChatRoomForm')},

    {path: '*', name: 'notfound', component: () => import('@/views/AppNotFound')},
];

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.name === 'talk' || to.name === 'talk-comment') {
            return;
        }
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
});
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters.isLogin) {
            console.log(store.state.readTutorial);
            if (store.state.readTutorial) {
                // チュートリアルを見たならログインへ
                next({
                    path: '/login',
                    query: {redirect: to.fullPath},
                });
            } else {
                next({
                    path: '/tutorial',
                    query: {redirect: to.fullPath},
                });
            }
        } else {
            next();
        }
    } else {
        next();
    }
});
router.afterEach((to) => {
    const bodyId = to.meta.bodyId ? to.meta.bodyId : to.name;
    document.getElementsByTagName('body')[0].setAttribute('id', bodyId);
});

export default router;
