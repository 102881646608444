import Vue from 'vue';
import VueCookie from 'vue-cookie';

Vue.use(VueCookie);

export default {
  set_cookie (key, value, expiresDay) {
    // cookieに保存
    return VueCookie.set(key, value, expiresDay);
  },
  get_cookie (key) {
    return VueCookie.get(key);
  }
};
