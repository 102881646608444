<template>
  <div class="body">
    <router-view/>
    <modal name="modal-loading" :adaptive="false" class="modal-wrapper"
           width="100%" height="100%"
           :styles="{backgroundColor:'transparent',boxShadow:'none',}">
      <a href="#!" class="modal-overlay"></a>
      <div class="spinner">
        <div class="rect1"></div>
        <div class="rect2"></div>
        <div class="rect3"></div>
        <div class="rect4"></div>
        <div class="rect5"></div>
      </div>
    </modal>
  </div>
</template>

<script>
import '@/assets/css/style.css';
export default {
  computed: {
    loading () {
      return this.$store.state.loading;
    }
  },
  created () {
  },
  watch: {
    loading (val) {
      if (val) {
        this.$modal.show('modal-loading');
      } else {
        this.$modal.hide('modal-loading');
      }
    }
  }
}
</script>

<style scoped>
/* ローディング
---------------------------------------------------- */

.spinner {
  /*	margin: 100px auto;*/
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
  z-index: 20;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.spinner > div {
  background-color: #fff;
  height: 100%;
  width: 6px;
  display: inline-block;
  border-radius: 2px;
  margin: 0 1px;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}
.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}
.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}
@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
       transform: scaleY(1.0);
       -webkit-transform: scaleY(1.0);
     }
}


</style>