import Vue from 'vue'
import Vuex from 'vuex'
import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginUser: {
      id:null
    },
    talkRoomId: null,
    loading: false,
    readTutorial: false,
    talkRoom: {
      selectedUser: null,
      preview_image: null,
      selectedRemark: null,
      lastReadTalkRoomRemarks: {},
      lastReadTalkRoom: null,
    },
    cart: [],
    storedDigitalContents: [],
  },
  getters,
  mutations,
  actions,
  plugins: [createPersistedState({
    key: '333vip',
    paths: [
      'loginUser',
      'readTutorial',
      'talkRoom',
      'cart',
    ],
    // storage: window.sessionStorage
  })],
  modules: {
  }
})
