import Vue from 'vue';
import cookieService from '../services/cookie';

export const signIn = async ({commit}, token) => {
    const expiresDay = 365;
    cookieService.set_cookie('token', token, expiresDay);
    Vue.http.defaults.headers.common['Authorization'] = 'Bearer ' + token;

    // ログインユーザー情報取得
    const responseUser = await Vue.http.get('me');
    commit('setLoginUser', responseUser.data);

    return true;
};
export const signOut = async ({commit}) => {
    Vue.http.post('logout');
    commit('setLoginUser', {id:null});
};

export const signInArtist = async (token) => {
    const expiresDay = 365;
    cookieService.set_cookie('token', token, expiresDay);
    Vue.http.defaults.headers.common['Authorization'] = 'Bearer ' + token;

    return true;
};