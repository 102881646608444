import axios from 'axios';

const http = axios;

export default (Vue, {store, router}) => {
    Vue.http = http.create({
        headers: {
            common: {
                'X-Requested-With': 'XMLHttpRequest',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + Vue.cookie.get('token')
            },
        },
        // baseURL: process.env.VUE_APP_API_ROOT,
        baseURL: '/api',
        withCredentials: true,
    });
    Vue.http.interceptors.request.use((config) => {
        store.commit('setLoading');
        return config;
    });
    Vue.http.interceptors.response.use(
        response => {
            store.commit('resetLoading');
            return response;
        },
        error => {
            store.commit('resetLoading');
            console.log(error);
            if (error.response.status === 401) {
                store.commit('setLoginUser', {id:null});
               
                //place your reentry code
                Vue.toasted.show('タイムアウトによりログアウトされました', {
                    position: 'top-center',
                    duration: 5000,
                    fullWidth: true,
                    type: 'error',
                });
                router.push({name: 'login'});
                return Promise.reject(error);
            }
            // Vue.toasted.show('エラー！' + error.message, {
            //     position: 'top-center',
            //     duration: 5000,
            //     fullWidth: true,
            //     type: 'error',
            // });
            return Promise.reject(error);
        },
    );
    Object.defineProperties(Vue.prototype, {
        $http: {
            get() {
                return Vue.http;
            },
        },
    });
};